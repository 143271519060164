<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain type-lhc twlh game-twlh game-lhc-sxd">
        <div class="data ctop">
          <h3 class="h3_text_left">
            <div class="h3_label">合肖</div>
            <span class="titleOdds"> {{titleOdds}}</span>
          </h3>
        </div>
        <div class="data">
          <ul class="custome_ul">
            <li v-for="item in sxArrData" :class="{selected:item.selected}" @click="setCurrentSelected(item)" :key="item.label">
              <ol class="td_name">
                {{
                  item.label
                }}
              </ol>
              <ol style="width: 200px" class="td_balls">
                <template v-for="ele in item.balls">

                  <div v-if="ele!='49'" :key="ele.num" :class="colorArr[Number(ele)-1] + 'ball ' + 'b' + ele">
                    {{ ele.num }}
                  </div>

                </template>

              </ol>
            </li>
          </ul>
        </div>
      </div>
      <yushe />
    </div>

    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "lhchexiao",
  props: [""],
  data() {
    return {
      groupnames:'hx',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [{ title: "合肖", arr: [] }],
      sxArrData: [
        {
          label: "鼠",
          selected: false,
        },
        {
          label: "牛",
          selected: false,
        },
        {
          label: "虎",
          selected: false,
        },
        {
          label: "兔",
          selected: false,
        },
        {
          label: "龙",
          selected: false,
        },
        {
          label: "蛇",
          selected: false,
        },
        {
          label: "马",
          selected: false,
        },
        {
          label: "羊",
          selected: false,
        },
        {
          label: "猴",
          selected: false,
        },
        {
          label: "鸡",
          selected: false,
        },
        {
          label: "狗",
          selected: false,
        },
        {
          label: "猪",
          selected: false,
        },
      ],
      labelArr: [
        "二肖",
        "三肖",
        "四肖",
        "五肖",
        "六肖",
        "七肖",
        "八肖",
        "九肖",
        "十肖",
        "十一肖",
      ],
      oddArr: [],
      title: "",
      titleId: "",
      titleOdds: "",
      selectedArr: [],
    };
  },
  mixins: [mixins],
  components: {
    topTimer,
    yushe,
    yanzheng,
  },
  computed: {
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },
  created() {
    this.sxArrData.forEach((item, index) => {
      item.balls = this.ballArr[index];
    });
    // console.log(this.sxArrData, "生肖Arr", this.ballArr);
  },
  watch: {
    pan(val) {
      this.sxArrData.forEach((item) => {
        item.selected = false;
      });
      this.selectedArr = [];
    },
    selectedArr(val) {
      this.watchData();
    },
    amount() {
      this.watchData();
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let arr1 = this.filterData(resAArr, 1887, 1896, this.labelArr);
        this.rowsData[0].arr = arr1;

        if (this.titleId && this.ISOPEN) {
          let currentItem = this.rowsData[0].arr.find(
            (item) => item.ResultID == this.titleId
          );
          this.titleOdds = currentItem.recordOdds;
        } else {
          if (!this.ISOPEN) {
            this.titleOdds = "--";
          }
        }
      },
    },
  },
  methods: {
    watchData() {
      if (this.selectedArr.length < 2) {
        this.title = "";
        this.titleId = "";
        this.titleOdds = "";
        this.$store.commit("setXZList", []);
      } else {
        let currentIndex = this.selectedArr.length - 2;
        this.title = this.rowsData[0].arr[currentIndex].label;
        this.titleId = this.rowsData[0].arr[currentIndex].ResultID;

        this.titleOdds = this.rowsData[0].arr[currentIndex].Odds;

        let arr1 = [];
        this.selectedArr.forEach((item) => {
          arr1.push(item.label);
        });
        let obj = {
          label: arr1.join(","),
          num: arr1.join(","),
          Odds: this.titleOdds,
          title: this.title,
          id: this.titleId,
          money: Number(this.amount),
        };
        this.$store.commit("setXZList", [obj]);
      }
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },

    // 选中快捷金额
    setCurrentName(item, num) {
      item.cash = num;
      item.b = false;
      console.log(item, num);
      this.$forceUpdate();
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },

    //   点击当前li
    setCurrentSelected(ele) {
      if (!ele.selected && this.selectedArr.length == 11) {
        this.$message.warning("已超出最大数量");
        return;
      }
      ele.selected = !ele.selected;
      this.selectedArr = [];
      this.sxArrData.forEach((item) => {
        if (item.selected) {
          this.selectedArr.push(item);
        }
      });
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.titleOdds {
  color: red;
}
.h3_label {
  display: inline-block;
  width: 65px;
  height: 25px;
  text-align: center;
  border-right: 1px solid #b9c2cb;
}
.h3_text_left {
  text-align: left !important;
}
.custome_ul {
  height: 204px;
  display: flex;
  flex-direction: column !important;
}

.listmain .data ul li {
  height: 34px;
  width: 50%;
}
</style>
